import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog, TableComponent, TopNavBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { CreateUploadPopup } from "./createUpload";
import { EventUseStyles } from "./style";
import { EventReqJson } from "./tableData";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import ViewEvent from "./viewEvent";
import { imageUploads } from "./utils";
// import ViewEvent from "./viewEvent";

export const AboutUsIconUpload = (props) => {
  const { t } = useTranslation(["topBar"]);
  const alert = React.useContext(AlertContext);
  const classes = EventUseStyles();

  const initialState = {
    eventType: [],
    cmpnyName: "",
    description: "",
    event_title: "",
    partner_type: null,
    from: "",
    to: "",
    // event_master_id: "",
    id: "",
    file_image: null,
    url: null,
    file_image_url: "",
    created_at: null,
    is_active: false,
    error: {
      eventType: [],
      cmpnyName: "",
      description: "",
      event_title: "",
      partner_type: "",
      from: "",
      to: "",
      // event_master_id: "",
      url: null,
      file_image: "",
      file_image_url: "",
      created_at: null,
      is_active: false,
    },
  };

  const [value, setValue] = React.useState({ ...initialState });
  const [eventList, setEventList] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const [popup, setpopup] = React.useState(false);
  const [event, setEvent] = React.useState({
    open: false,
    is_active: false,
  });
  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });
  const viewEvent = (params) => {
    setEvent({ open: true, ...params });
  };

  const fileOnChange = async (e) => {
    const samplevalue = JSON.parse(JSON.stringify(value));

    const updateTheImage = await imageUploads(e?.target?.files[0]);

    if (!e) {
      samplevalue.file_image_url = "";
      // setValue({ ...value, file_image_url: "" });
      setValue({ ...samplevalue });
      return;
    }
    //
    // samplevalue.file_image = e.target.files[0]
    // setValue({ ...samplevalue, file_image_url: e.target.files[0], file_image: URL.createObjectURL(e.target.files[0]) });
    setValue({
      ...samplevalue,
      file_image_url: e.target.files[0],
      file_image: updateTheImage,
    });
    //  onChange(e);
  };

  const updateState = (k, v) => {
    let error = value?.error;
    error[k] = "";
    setValue({ ...value, [k]: v, error });
  };

  const Dialog = React.useContext(DialogContext);

  const dialogClose = () => {
    Dialog.setDialog({
      open: false,
    });
  };

  const handleEventActiveConfirmation = (status) => {
    if (status === true) {
      Dialog.setDialog({
        open: true,
        title: "Are you sure?",
        body: "This will make all events in active, Proceed with caution!",
        positiveBtn: "Agree",
        negativeBtn: "Cancel",
        onOk: () => {
          updateState("is_active", status);
          dialogClose();
        },
        onCancel: () => {
          updateState("is_active", false);
          dialogClose();
        },
      });
    } else {
      updateState("is_active", false);
      dialogClose();
    }
  };
  const isValid = () => {
    //
    let isValid = true;
    let error = value.error;

    if (value?.cmpnyName?.length === 0) {
      isValid = false;
      error.cmpnyName = "Enter Name";
    } else {
      error.cmpnyName = "";
    }
    if (value?.partner_type?.length === 0) {
      isValid = false;
      error.partner_type = "Enter Partner Type";
    } else {
      error.partner_type = "";
    }
    if (value?.file_image_url?.length === 0) {
      isValid = false;
      error.file_image_url = "Add Image";
    } else {
      error.file_image_url = "";
    }
    // if (value?.event_title?.length === 0) {
    //   isValid = false;
    //   error.event_title = "Enter Event Title";
    // } else {
    //   error.event_title = "";
    // }
    // if (value?.partner_type?.length === 0) {
    //   isValid = false;
    //   error.partner_type = "Enter Partner Type";
    // } else {
    //   error.partner_type = "";
    // }
    // if (!value?.id) {
    //   if (!value?.url?.name) {
    //     isValid = false;
    //     error.url = "Upload Image";
    //   } else {
    //     error.url = "";
    //   }
    // }

    setValue({ ...value, error });
    return isValid;
  };

  const addEvent = () => {
    let payload = {};
    if (isValid()) {
      if (edit.isEdit) {
        payload = {
          upsert_fields: {
            id: value?.id,
            name: value.cmpnyName,
            url: value?.file_image?.response ?? "",
            type: 1,
            partners: value?.partner_type?.value,
            // partner_type:,
            file_meta: value?.file_image?.fileData ?? { name: "", size: "" },
          },
        };
      } else {
        payload = {
          upsert_fields: {
            name: value.cmpnyName,
            url: value?.file_image?.response ?? "",
            type: 1,
            partners: value?.partner_type?.value,
            // partner_type:,
            file_meta: value?.file_image?.fileData ?? { name: "", size: "" },
          },
        };
      }
      NetworkCall(
        `${config.api_feature}api/v1/partners/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          let main = res.data;
          getEventList(main);
          // getEventList();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          setValue({ ...initialState });
          setpopup(!popup);
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.EventErr,
          });
        });
    }
  };

  React.useEffect(() => {
    getEventType();
    getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/partners/all_partners_types`,
      NetWorkCallMethods.get,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.data ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });

        setoptions(tempOptions);
        setValue({ ...value, partner_type: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PopupSubmitHandler = () => {
    // if (isValid) {
    //   addEvent();
    // setValue({ ...initialState });
    // setpopup(!popup);

    // } else {
    //   alert("err")
    // }
    addEvent();

    // setValue({ ...initialState });
    // setpopup(!popup);
  };

  const getEventList = () => {
    let params = {
      search: "",
      limit: 100,
      offset: 0,
      is_active: [true, false],
    };
    NetworkCall(
      `${config.api_feature}api/v1/partners`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let tempList = res?.data?.data ?? [];
        setEventList(
          tempList?.map((item, index) => {
            return { sl: index + 1, file_img: item?.file_meta?.name, ...item };
          })
        );
        tempList?.map((e, index) => {
          return setValue({
            ...value,
            id: e.id,
            is_active: e.is_active,
            sl: index,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDrawerEdit = (row, param, event, rowIndex) => {
    setValue({
      ...value,
      id: row?.id ?? "",
      cmpnyName: row?.name ?? "",
      file_image: {
        fileData: {
          name: row?.file_meta?.name,
          size: row?.file_meta?.size,
        },
        response: row?.url,
      },
      file_image_url: {
        name: row?.file_meta?.name,
        size: row?.file_meta?.size,
      },
      partner_type: options.filter(
        (val) => val.label === row.partner_type
      )?.[0],
    });
    setEdit({ ...edit, isEdit: true, editIndex: rowIndex });
    setpopup(true);
    // editEvent()
  };

  // const onDelete = (row) => {
  //   const eventId = row?.id;
  //   //
  // };
  const onImageDelete = () => {
    setValue({ ...value, file_image: null, file_image_url: null });
  };

  const onDelete = (row) => {
    let params = {
      upsert_fields: {
        id: row?.id,
        // "is_active": true ,// on status change
        is_delete: true, // on delete
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/partners/upsert`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        // let response = res?.data?.query ?? [];
        // let tempOptions = response?.map((item, index) => {
        //   return {
        //     label: item.name,
        //     value: item.id,

        //     // data:item
        //   };
        // });
        // setoptions(tempOptions);
        // setValue({ ...value, eventType: tempOptions[0] });
        // const eventId = row?.id;
        getEventList();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted Successfully",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // toggle btn

  // const toggle = (row) => {
  //   //
  //   let params = {
  //     id: row?.id,
  //     update_fields: {
  //       is_active: !row?.is_active, // on status change
  //       // "is_delete":true // on delete
  //     },
  //   };
  //   NetworkCall(
  //     // /every_one_become/upsert
  //     `${config.api_feature}api/v1/events/update_event`,
  //     NetWorkCallMethods.post,
  //     params,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((res) => {
  //       // let response = res?.data?.query ?? [];
  //       // let tempOptions = response?.map((item, index) => {
  //       //   return {
  //       //     label: item.name,
  //       //     value: item.id,

  //       //     // data:item
  //       //   };
  //       // });
  //       // setoptions(tempOptions);
  //       // setValue({ ...value, eventType: tempOptions[0] });
  //       // const eventId = row?.id;
  //       let main = res.data;
  //       //
  //       getEventList();
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.success,
  //         msg: "Event is Activated",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const popupClose = () => {
    setpopup(!popup);
    setValue({ ...initialState });
  };

  return (
    <>
      <TopNavBar
        title={t("Provision to Add Company Name and Icons")}
        buttonLabel={false}
      />
      <div className={classes.root}>
        {/* <ExcelFile style={{ display: "flex" }} element={<Typography className={classes.btn}>Download CSV</Typography>}>
                    <ExcelSheet dataSet={excelJson} name="fdskj" />
                </ExcelFile> */}
        <Box className={classes.btnBox}>
          <Stack direction="row" className={classes.btnGrp}>
            <Typography
              className={classes.btn}
              onClick={() => {
                setpopup(!popup);
                setValue({ ...initialState });
              }}
            >
              Add Logo
            </Typography>
            {/* <Typography className={classes.btn} ml={2}>
              Filter
            </Typography> */}
          </Stack>
        </Box>

        <TableComponent
          dataList={eventList}
          Header={EventReqJson(onDrawerEdit, viewEvent, onDelete)}
          isChecked
          cellClick={(rowData) => console.log(rowData)}
        />

        <AlertDialog
          open={popup}
          onClose={() => popupClose()}
          header={value?.id ? "Edit Logo / Name" : "Add Logo / Name"}
          // "Add Event"
          component={
            <CreateUploadPopup
              options={options}
              value={value}
              updateState={updateState}
              PopupSubmitHandler={PopupSubmitHandler}
              fileOnChange={fileOnChange}
              onImageDelete={onImageDelete}
              handleEventActiveConfirmation={handleEventActiveConfirmation}
            />
          }
          isNormal
        />
        <ViewEvent
          {...event}
          handleClose={() => {
            setEvent({ ...event, open: false });
          }}
        />
      </div>
    </>
  );
};
