import React from "react";
import { withNavBars } from "../../HOCs";
import { EveryOneBecomePartner } from "./everyOneBecomePartner";

class EveryOneBecomePartnerParent extends React.Component {
  render() {
    return <EveryOneBecomePartner />;
  }
}

export default withNavBars(EveryOneBecomePartnerParent);
