import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const EventReqJson = (onEdit, onView, onDelete) => {
  return {
    TablecolumnData: [
      // { field: "name", headerName: "Name", width: 140 },
      { field: "sl", headerName: "S.No", width: 80 },
      { field: "department_name", headerName: "Department", width: 200 },
      { field: "position_name", headerName: "Position", width: 200 },
      { field: "country", headerName: "Location", width: 200 },
      {
        field: "job_description",
        headerName: "Job Description",
        width: 400,
        renderCell: (params) => {
          return <div dangerouslySetInnerHTML={{ __html: params?.value }} />;
        },
      },
      {
        field: "task_responsibilities",
        headerName: "Task Responsibilities",
        width: 400,
        // whiteSpace: nowrap,
        // textOverflow: ellipsis,
        renderCell: (params) => {
          return <div dangerouslySetInnerHTML={{ __html: params?.value }} />;
        },
      },
      {
        field: "qualification",
        headerName: "Qualification",
        width: 400,
        renderCell: (params) => {
          return <div dangerouslySetInnerHTML={{ __html: params?.value }} />;
        },
      },
      //   { field: "task_responsibilities", headerName: "Task Responsibilities", width: 400 },
      //   { field: "qualification", headerName: "Qualification", width: 400 },
      { field: "email_id", headerName: "Email ID", width: 200 },

      // {
      //   field: "from",
      //   headerName: "From",
      //   width: 140,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
      // {
      //   field: "to",
      //   headerName: "To",
      //   width: 140,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
      // { field: "event_name", headerName: "Event Type", width: 150 },

      //   {
      //     field: "status",
      //     headerName: "Status",
      //     width: 100,
      //     renderCell: (params) => {
      //       return (
      //         <IconButton disableRipple onClick={() => onSwitchBtn(params?.row)}>
      //           <Switch checked={params?.row?.is_active} />
      //         </IconButton>
      //       );
      //     },
      //   },
      {
        field: "view",
        headerName: "View",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => onView(params?.row)}>
              <VisibilityIcon />
            </IconButton>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onEdit(params.row, params, e)}>
              <EditIcon />
            </IconButton>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onDelete(params.row, params, e)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    TableData: [],
  };
};
