import React from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box } from "@mui/system";
import { Upload } from "../../components/upload/fileupload";
import { EventUseStyles } from "./style";

export const CreateUploadPopup = (props) => {
  const {
    options,
    value,
    updateState,
    PopupSubmitHandler,
    fileOnChange,
    onImageDelete,
  } = props;

  const classes = EventUseStyles();
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="partner_type"
            options={options ?? []}
            getOptionLabel={(option) => option?.label}
            autoSelect
            renderInput={(params) => (
              <TextField {...params} label="Partner Type" />
            )}
            // defaultValue={{ label: "webinar", value: 1 }}
            value={value?.partner_type}
            onChange={(event, newValue) => {
              updateState("partner_type", newValue);
            }}
          />
        </Box>
      </Grid>
      {/* {value.error.eventName } */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Company Name"
            value={value.cmpnyName}
            onChange={(e) => {
              updateState("cmpnyName", e.target.value);
            }}
            error={value.error.cmpnyName.length > 0}
          />
        </Box>
        {value.error.cmpnyName.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.cmpnyName}
          </Typography>
        )}
      </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Part Name"
            value={value.partner_type}
            onChange={(e) => {
              updateState("partner_type", e.target.value);
            }}
            error={value.error.partner_type.length > 0}
            errorMsg={value.error.partner_type}
          />
        </Box>
        {value.error.partner_type.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.partner_type}
          </Typography>
        )}
      </Grid> */}
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className={classes.frstRow}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  toolbarFormat="YYYY-MM-DD hh:mm:ss"
                  label="Start date"
                  value={value.from}
                  onChange={(e) => updateState("from", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className={classes.frstRow}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  toolbarFormat="YYYY-MM-DD hh:mm:ss"
                  label="End date"
                  value={value.to}
                  onChange={(e) => updateState("to", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Grid> */}
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Description"
            value={value.description}
            onChange={(e) => {
              updateState("description", e.target.value);
            }}
          />
        </Box>
        {value.error.description.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.description}
          </Typography>
        )}
      </Grid> */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.finalRow}>
          {value?.file_image_url || value?.file_image ? (
            <img
              style={{ height: 100, width: 380 }}
              src={
                value?.file_image?.response
                  ? value?.file_image?.response
                  : value?.file_image_url
              }
              alt="upload_image"
            />
          ) : (
            <Upload fileOnChange={fileOnChange} single value={value} />
          )}
          {(value?.file_image_url || value?.file_image) && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => onImageDelete()}
            >
              {" "}
              <DeleteForeverIcon style={{ color: "#FF300F" }} />
            </div>
          )}
        </Box>
      </Grid>
      {/* {value.error.file_image?.name?.length > 0 ? "true" : "false"} */}
      {value.error.file_image && (
        <Typography className={classes.errorMsg}>
          {value.error.file_image}
        </Typography>
      )}
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.is_active}
                onChange={(event) => {
                  handleEventActiveConfirmation(event?.target?.checked);
                }}
              />
            }
            label="Mark as active"
          />
        </FormGroup>
      </Grid> */}

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ padding: "8px 16px 14px 16px" }}
      >
        <Typography
          className={classes.btn}
          onClick={(e) => PopupSubmitHandler(e)}
        >
          Add
        </Typography>
      </Grid>
    </Grid>
  );
};
