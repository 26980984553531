import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog, TableComponent, TopNavBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { CreateUploadPopup } from "./createUpload";
import { EventUseStyles } from "./style";
import { EventReqJson } from "./tableData";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import ViewEvent from "./viewEvent";
import { imageUploads } from "./utils";
import { useState } from "react";
// import ViewEvent from "./viewEvent";

export const LandingPageUpload = (props) => {
  const { t } = useTranslation(["topBar"]);
  const alert = React.useContext(AlertContext);
  const classes = EventUseStyles();

  const initialState = {
    eventType: [],
    cmpnyName: "",
    description: "",
    event_title: "",
    partner_type: "",
    poster: "",
    alter_text: "",
    position: "",
    from: "",
    to: "",
    // event_master_id: "",
    id: "",
    file_image: null,
    url: null,
    file_image_url: "",
    created_at: null,
    is_active: false,
    error: {
      eventType: [],
      cmpnyName: "",
      description: "",
      event_title: "",
      partner_type: "",
      poster: "",
      alter_text: "",
      position: "",
      from: "",
      to: "",
      // event_master_id: "",
      url: null,
      file_image: "",
      file_image_url: "",
      created_at: null,
      is_active: false,
    },
  };

  const [value, setValue] = React.useState({ ...initialState });
  const [eventList, setEventList] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const [popup, setpopup] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [event, setEvent] = React.useState({
    open: false,
    is_active: false,
  });
  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });
  const viewEvent = (params) => {
    setEvent({ open: true, ...params });
  };

  const onImageDelete = () => {
    value.file_image = null;
    value.file_image_url = null;
    setValue({ ...value });
  };
  const fileOnChangePoster = async (e) => {
    const updatePoster = JSON.parse(JSON.stringify(value));
    if (e?.target?.files[0]) {
      const poster = await imageUploads(e?.target?.files[0]);

      setValue({
        ...updatePoster,
        poster: poster,
      });
    }
  };
  const fileOnChange = async (e) => {
    setLoading(true);

    const file = e?.target?.files[0];
    const fileSizeInBytes = file?.size;
    const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMegabytes < 4) {
      // Additional logic for handling the file

      const samplevalue = JSON.parse(JSON.stringify(value));

      const updateTheImage = await imageUploads(e?.target?.files[0]);

      if (updateTheImage) {
        setLoading(false);
      }

      if (!e) {
        samplevalue.file_image_url = "";
        // setValue({ ...value, file_image_url: "" });
        setValue({ ...samplevalue });
        return;
      }
      let error = value?.error;
      error.file_image = "";
      setValue({
        ...samplevalue,
        file_image_url: updateTheImage, // Use the URL returned from imageUploads
        file_image: updateTheImage,
        error,
      });
    } else {
      // Additional logic for handling the error or notifying the user
      let error = value?.error;
      error.file_image = "Upload Image below 3 MB ";

      setLoading(false);
      setValue({ ...value, error });
    }

    //  onChange(e);
  };
  const updateState = (k, v) => {
    let error = value?.error;
    let update = { ...value };
    update[k] = v;
    if (k === "partner_type" && v?.label === "Image") {
      update["poster"] = "";
    }
    error[k] = "";
    setValue({ ...value, ...update, error });
  };
  const Dialog = React.useContext(DialogContext);

  const dialogClose = () => {
    Dialog.setDialog({
      open: false,
    });
  };

  const changePosterDelete = () => {
    setValue({ ...value, poster: "" });
  };

  const handleEventActiveConfirmation = (status) => {
    if (status === true) {
      Dialog.setDialog({
        open: true,
        title: "Are you sure?",
        body: "This will make all events in active, Proceed with caution!",
        positiveBtn: "Agree",
        negativeBtn: "Cancel",
        onOk: () => {
          updateState("is_active", status);
          dialogClose();
        },
        onCancel: () => {
          updateState("is_active", false);
          dialogClose();
        },
      });
    } else {
      updateState("is_active", false);
      dialogClose();
    }
  };
  const isValid = () => {
    //
    let isValid = true;
    let error = value.error;
    const isPrevPosition = eventList?.find(
      (val) => val.priority === Number(value?.position)
    );

    // if (value?.cmpnyName?.length === 0) {
    //   isValid = false;
    //   error.cmpnyName = "Enter Name";
    // } else {
    //   error.cmpnyName = "";
    // }
    // if (value?.partner_type?.length === 0) {
    //   isValid = false;
    //   error.partner_type = "Enter Partner Type";
    // } else {
    //   error.partner_type = "";

    // }

    // if (value?.event_title?.length === 0) {
    //   isValid = false;
    //   error.event_title = "Enter Event Title";
    // } else {
    //   error.event_title = "";
    // }
    // if (value?.partner_type?.length === 0) {
    //   isValid = false;
    //   error.partner_type = "Enter Partner Type";
    // } else {
    //   error.partner_type = "";
    // }
    // if (!value?.id) {


    setValue({ ...value, error });
    return isValid;
  };

  const addEvent = () => {
    let payload = {};
    if (isValid()) {
      if (edit.isEdit) {
        payload = {
          upsert_fields: {
            id: value?.id,
            name: value?.alter_text,
            url: value?.file_image?.response,
            type: value?.partner_type?.value,
            priority: value?.position,
            file_meta: value?.file_image?.fileData
              ? {
                  ...value?.file_image?.fileData,
                  thumbnail: value?.poster?.response,
                }
              : { name: "", size: "", thumbnail: value?.poster?.response },
          },
        };
      } else {
        payload = {
          upsert_fields: {
            name: value?.alter_text,
            url: value?.file_image?.response,
            type: value?.partner_type?.value,
            priority: value?.position,
            file_meta: value?.file_image?.fileData
              ? {
                  ...value?.file_image?.fileData,
                  thumbnail: value?.poster?.response,
                }
              : { name: "", size: "", thumbnail: value?.poster?.response },
          },
        };
      }
      NetworkCall(
        `${config.api_feature}api/v1/banner_configuration/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          let main = res.data;
          getEventList(main);
          // getEventList();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          setpopup(!popup);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.EventErr,
          });
        });
    }
  };

  React.useEffect(() => {
    getEventType();
    getEventList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/banner_configuration/get_asset_type`,
      NetWorkCallMethods.get,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        // let response = res?.data?.data ?? [];
        let response = res?.data?.list ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });

        setoptions(tempOptions);
        setValue({ ...value, partner_type: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PopupSubmitHandler = async () => {
    // if (isValid) {
    //   addEvent();
    // setValue({ ...initialState });
    // setpopup(!popup);

    // } else {
    //   alert("err")
    // }
    await addEvent();
    // setValue({ ...initialState });
    setEdit({ isEdit: false, id: "", isView: false });
    // setValue({ ...initialState });
    // setpopup(!popup);
  };

  const getEventList = () => {
    let params = {
      search: "",
      limit: 100,
      offset: 0,
      is_active: [true, false],
    };
    NetworkCall(
      `${config.api_feature}api/v1/banner_configuration/get_all_banner_configuration`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let tempList = res?.data?.data ?? [];
        setEventList(
          tempList?.map((item, index) => {
            return {
              sl: index + 1,
              id: index + 1,
              file_img: item?.file_meta?.name,
              ...item,
            };
          })
        );
        tempList?.map((e, index) => {
          return setValue({
            ...value,
            id: e.id,
            is_active: e.is_active,
            sl: index,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDrawerEdit = (row, param, event, rowIndex) => {
    setValue({
      ...value,
      id: row?.id ?? "",
      file_image: {
        fileData: {
          name: row?.file_meta?.name,
          size: row?.file_meta?.size,
        },
        response: row?.url,
      },
      file_image_url: {
        name: row?.file_meta?.name,
        size: row?.file_meta?.size,
      },
      partner_type: options.filter(
        (val) => val.label === row.partner_type
      )?.[0],
      poster: row?.file_meta.name,
      alter_text: row?.name,
      position: row?.priority,
      partner_type: row?.type,
    });
    setEdit({ ...edit, isEdit: true, editIndex: rowIndex });
    setpopup(true);
    // editEvent()
  };

  // const onDelete = (row) => {
  //   const eventId = row?.id;
  //   //
  // };
  const onDelete = (row) => {
    let params = {
      upsert_fields: {
        id: row?.id,
        // "is_active": true ,// on status change
        is_delete: true, // on delete
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/banner_configuration/upsert`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        // let response = res?.data?.query ?? [];
        // let tempOptions = response?.map((item, index) => {
        //   return {
        //     label: item.name,
        //     value: item.id,

        //     // data:item
        //   };
        // });
        // setoptions(tempOptions);
        // setValue({ ...value, eventType: tempOptions[0] });
        // const eventId = row?.id;
        getEventList();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted Successfully",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const popupOpen = () => {
    setValue({ ...initialState });
    setEdit({ isEdit: false, id: "", isView: false });
    setpopup(!popup);
  };

  const popupClose = () => {
    setValue({ ...initialState });
    setpopup(!popup);
  };

  return (
    <>
      <TopNavBar
        title={t("Provision to Add Images and Videos")}
        buttonLabel={false}
      />
      <div className={classes.root}>
        {/* <ExcelFile style={{ display: "flex" }} element={<Typography className={classes.btn}>Download CSV</Typography>}>
                    <ExcelSheet dataSet={excelJson} name="fdskj" />
                </ExcelFile> */}
        <Box className={classes.btnBox}>
          <Stack direction="row" className={classes.btnGrp}>
            <Typography className={classes.btn} onClick={popupOpen}>
              Add Image / Video
            </Typography>
            {/* <Typography className={classes.btn} ml={2}>
              Filter
            </Typography> */}
          </Stack>
        </Box>

        <TableComponent
          dataList={eventList}
          Header={EventReqJson(onDrawerEdit, viewEvent, onDelete)}
          isChecked
          cellClick={(rowData) => console.log(rowData)}
        />

        <AlertDialog
          open={popup}
          onClose={() => popupClose()}
          header={value?.id ? "Edit Event" : "Upload Image/video"}
          // "Add Event"
          component={
            <CreateUploadPopup
              options={options}
              value={value}
              updateState={updateState}
              PopupSubmitHandler={PopupSubmitHandler}
              submitButton={Boolean(value?.id) ? "Update" : "Add"}
              isLoading={isLoading}
              fileOnChange={fileOnChange}
              fileOnChangePoster={fileOnChangePoster}
              onImageDelete={onImageDelete}
              handleEventActiveConfirmation={handleEventActiveConfirmation}
              changePosterDelete={changePosterDelete}
              tableData={eventList}
            />
          }
          isNormal
        />
        <ViewEvent
          {...event}
          handleClose={() => {
            setEvent({ ...event, open: false });
          }}
        />
      </div>
    </>
  );
};
