import React from "react";
import { withNavBars } from "../../HOCs";
import CreateBlogPage from "./createBlog";

class CreateBlogParent extends React.Component {
  render() {
    return <CreateBlogPage />;
  }
}

export default withNavBars(CreateBlogParent);
