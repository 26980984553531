import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  DialogTitle,
  Typography,
} from "@mui/material";
import { EventUseStyles } from "./style";

const ViewEvent = (props) => {
  const classes = EventUseStyles();
  let { open, handleClose, partner_type, url, name, description } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.eventTitle}>{partner_type}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {url && (
              <img
                className={classes.eventImg}
                src={url}
                alt={name}
                height="100%"
                width="100%"
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.eventName}>{name}</Typography>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEvent;
