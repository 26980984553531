import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "crayond_semibold",
    fontSize: "14px",
    marginBottom: "5px",
  },
  text: {},

  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    // marginBottom:"10px"
  },
}));
