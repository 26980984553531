import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { AlertDialog, TableComponent, TopNavBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { CreatePopup } from "./createPopup";
import { CareersUseStyles } from "./style";
import { EventReqJson } from "./tableData";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import ViewEvent from "./viewEvent";

export const CareersUpload = (props) => {
  // START -------
  const { t } = useTranslation(["topBar"]);
  const alert = React.useContext(AlertContext);
  const classes = CareersUseStyles();

  const initialState = {
    eventType: [],
    eventName: "",
    task_responsibilities: "",
    qualification: "",
    position_name: "",
    department: "",
    location: "",
    country: "",
    job_description: "",
    description: "",
    email_id: "",
    from: "",
    to: "",
    event_master_id: "",
    id: "",
    file_image: null,
    file_image_url: "",
    created_at: null,
    is_active: false,
    error: {
      eventType: [],
      eventName: "",
      qualification: "",
      position_name: "",
      department: "",
      location: "",
      country: "",
      task_responsibilities: "",
      job_description: "",
      description: "",
      email_id: "",
      from: "",
      to: "",
      event_master_id: "",
      file_image: "",
      file_image_url: "",

      created_at: null,
      is_active: false,
    },
  };

  const [value, setValue] = React.useState({ ...initialState });
  const [eventList, setEventList] = React.useState([]);
  const [options, setoptions] = React.useState([]);

  const [cityOptions, setCityOptions] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [popup, setpopup] = React.useState(false);
  const [event, setEvent] = React.useState({
    open: false,
    is_active: false,
  });
  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
    editIndex: null,
  });

  const viewEvent = (params) => {
    setEvent({ open: true, ...params });
  };

  const fileOnChange = (e) => {
    const samplevalue = JSON.parse(JSON.stringify(value));
    if (!e) {
      samplevalue.file_image_url = "";
      samplevalue.file_image = null;
      // setValue({ ...value, file_image_url: "" });
      setValue({ ...samplevalue });
      return;
    }
    samplevalue.file_image = e.target.files[0];
    setValue({ ...samplevalue });
    //  onChange(e);
  };

  const updateState = (k, v) => {
    setValue({ ...value, [k]: v });
  };

  const Dialog = React.useContext(DialogContext);

  const dialogClose = () => {
    Dialog.setDialog({
      open: false,
    });
  };

  const handleEventActiveConfirmation = (status) => {
    if (status === true) {
      Dialog.setDialog({
        open: true,
        title: "Are you sure?",
        body: "This will make all events in active, Proceed with caution!",
        positiveBtn: "Agree",
        negativeBtn: "Cancel",
        onOk: () => {
          updateState("is_active", status);
          dialogClose();
        },
        onCancel: () => {
          updateState("is_active", false);
          dialogClose();
        },
      });
    } else {
      updateState("is_active", false);
      dialogClose();
    }
  };

  // const onDrawerEdit = (param, e) => {

  // };
  const isValid = () => {
    let isValid = true;
    let error = value.error;

    // if (value?.eventName?.length === 0) {
    //   isValid = false;
    //   error.eventName = "Enter Name";
    // } else {
    //   error.eventName = "";
    // }

    if (value?.job_description?.length === 0) {
      isValid = false;
      error.job_description = "Enter Job Description";
    } else {
      error.job_description = "";
    }

    if (value?.task_responsibilities?.length === 0) {
      isValid = false;
      error.task_responsibilities = "Enter Task Responsibilities";
    } else {
      error.task_responsibilities = "";
    }

    if (value?.qualification?.length === 0) {
      isValid = false;
      error.qualification = "Enter Qualification";
    } else {
      error.qualification = "";
    }

    if (value?.position_name?.length === 0) {
      isValid = false;
      error.position_name = "Enter position";
    } else {
      error.position_name = "";
    }

    // if (value?.description?.length === 0) {
    //     isValid = false;
    //     error.description = "Enter Description";
    // } else {
    //     error.description = "";
    // }
    // if (value?.event_master_id?.length === 0) {
    //   isValid = false;
    //   error.event_master_id = "Enter Event Type";
    // } else {
    //   error.event_master_id = "";
    //
    // }
    if (value?.email_id?.length === 0) {
      isValid = false;
      error.email_id = "Enter Email Id";
    } else {
      error.email_id = "";
    }
    if (
      value?.email_id?.length > 0 &&
      !/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value?.email_id)
    ) {
      isValid = false;
      error.email_id = "Please Fill Valid Mail ID";
    } else {
      error.email_id = "";
    }
    // if (!value?.id) {
    //   if (!value?.file_image?.name) {
    //     isValid = false;
    //     error.file_image = "Upload Image";
    //   } else {
    //     error.file_image = "";
    //   }
    // }

    setValue({ ...value, error });
    return isValid;
  };
  const addEvent = () => {
    let payload = {};
    if (isValid()) {
      if (edit.isEdit) {
        payload = {
          upsert_fields: {
            id: value?.id,
            job_description: value?.job_description,
            task_responsibilities: value?.task_responsibilities,
            qualification: value?.qualification,
            position_name: value?.position_name,
            department: value?.department_name?.value,
            location: value?.location_name?.value,
            country: value?.country_name?.value,
            email_id: value?.email_id,
            //   is_active:value?.is_active,
          },
        };
      } else {
        payload = {
          upsert_fields: {
            job_description: value?.job_description,
            task_responsibilities: value?.task_responsibilities,
            qualification: value?.qualification,
            position_name: value?.position_name,
            department: value?.department_name?.value,
            location: value?.location_name?.value,
            country: value?.country_name?.value,
            email_id: value?.email_id,
            //   is_active:value?.is_active,
          },
        };
      }
      NetworkCall(
        `${config.api_feature}api/v1/careers/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          let main = res.data;
          getEventList(main);
          // getEventList();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          setValue({ ...initialState });
          setpopup(!popup);
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.EventErr,
          });
        });
    } else {
    }
  };

  React.useEffect(() => {
    getEventType();
    getEventList();
    getCountryType();
    getCityType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/department_master`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.data ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });
        setoptions(tempOptions);
        setValue({ ...value, eventType: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/country_master`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.data ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });
        setCountryOptions(tempOptions);

        setValue({ ...value, country_name: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCityType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/location_master`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.data ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });
        setCityOptions(tempOptions);
        setValue({ ...value, country_name: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PopupSubmitHandler = () => {
    // if (isValid) {
    //   addEvent();
    // setValue({ ...initialState });
    // setpopup(!popup);

    // } else {
    //   alert("err")
    // }
    addEvent();

    // setValue({ ...initialState });
    // setpopup(!popup);
  };

  const getEventList = () => {
    let params = {
      search: "",
      limit: 100,
      offset: 0,
      is_active: [true, false],
    };
    NetworkCall(
      `${config.api_feature}api/v1/careers`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let tempList = res?.data?.data ?? [];
        setEventList(
          tempList?.map((item, index) => {
            return {
              sl: index + 1,
              country: `${item?.location_name}, ${item?.country_name}`,
              ...item,
            };
          })
        );
        tempList?.map((e, index) => {
          return setValue({
            ...value,
            id: e.id,
            is_active: e.is_active,
            sl: index,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDrawerEdit = async (row, param, event, rowIndex) => {
    await setValue({
      ...value,
      id: row?.id ?? "",
      job_description: row?.job_description ?? "",
      task_responsibilities: row?.task_responsibilities ?? "",
      qualification: row?.qualification ?? "",
      position_name: row?.position_name ?? "",
      event_master_id: row?.event_master_id ?? "",
      email_id: row?.email_id ?? "",
      is_active: row?.is_active ?? "",
      department_name: { label: row?.department_name, value: row?.department },
      location_name: { label: row?.location_name, value: row?.location },
      country_name: { label: row?.country_name, value: row?.country_id },
    });
    setEdit({ ...edit, isEdit: true, editIndex: rowIndex });
    setpopup(true);
  };

  const onDelete = (row) => {
    let params = {
      upsert_fields: {
        id: row?.id,
        // "is_active": true ,// on status change
        is_delete: true, // on delete
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/careers/upsert`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        getEventList();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted Successfully",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const toggle = (row) => {
  //   let params = {
  //     id: row?.id,
  //     update_fields: {
  //       is_active: !row?.is_active, // on status change
  //       // "is_delete":true // on delete
  //     },
  //   };
  //   NetworkCall(
  //     `${config.api_feature}api/v1/events/update_event`,
  //     NetWorkCallMethods.post,
  //     params,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((res) => {
  //       // let response = res?.data?.query ?? [];
  //       // let tempOptions = response?.map((item, index) => {
  //       //   return {
  //       //     label: item.name,
  //       //     value: item.id,

  //       //     // data:item
  //       //   };
  //       // });
  //       // setoptions(tempOptions);
  //       // setValue({ ...value, eventType: tempOptions[0] });
  //       // const eventId = row?.id;
  //       getEventList();
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.success,
  //         msg: "Event is Activated",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const popupClose = () => {
    setpopup(!popup);
    setValue({ ...initialState });
  };

  return (
    <>
      <TopNavBar title={t("Careers")} buttonLabel={false} />
      <div className={classes.root}>
        {/* <ExcelFile style={{ display: "flex" }} element={<Typography className={classes.btn}>Download CSV</Typography>}>
                    <ExcelSheet dataSet={excelJson} name="fdskj" />
                </ExcelFile> */}
        <Box className={classes.btnBox}>
          <Stack direction="row" className={classes.btnGrp}>
            <Typography
              className={classes.btn}
              onClick={() => {
                setpopup(!popup);
              }}
            >
              Create Event
            </Typography>
            {/* <Typography className={classes.btn} ml={2}>
              Filter
            </Typography> */}
          </Stack>
        </Box>

        <TableComponent
          dataList={eventList}
          Header={EventReqJson(onDrawerEdit, viewEvent, onDelete)}
          isChecked
          cellClick={(rowData) => console.log(rowData)}
        />

        <AlertDialog
          open={popup}
          onClose={() => popupClose()}
          header={value?.id ? "Edit Event" : "Create Event"}
          medium
          // "Add Event"
          component={
            <CreatePopup
              options={options}
              countryOptions={countryOptions}
              cityOptions={cityOptions}
              value={value}
              updateState={updateState}
              PopupSubmitHandler={PopupSubmitHandler}
              fileOnChange={fileOnChange}
              handleEventActiveConfirmation={handleEventActiveConfirmation}
            />
          }
          // isNormal
        />
        <ViewEvent
          {...event}
          handleClose={() => {
            setEvent({ ...event, open: false });
          }}
        />
      </div>
    </>
  );
};
