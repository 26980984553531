import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import moment from "moment";

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};
const istime = (cell, param) => {
  return <span>{moment(cell).format("HH:mm:ss")}</span>;
};

const MessageCell = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" style={{ backgroundColor: '#fbc9a3', color: 'grey' }} onClick={handleClickOpen}>
        View Message
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Message"}</DialogTitle>
        <DialogContent>
          {props.value}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const DemoReqJson = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "sl", headerName: "S.No", width: 80 },
      {
        field: "created_at",
        headerName: "Requested on",
        width: 150,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "updated_at",
        headerName: "Time of Lead Submission",
        width: 150,
        renderCell: (params) => {
          return istime(params.value, params);
        },
      },
      { field: "first_name", headerName: "First Name", width: 150 },
      { field: "last_name", headerName: "Last Name", width: 130 },
      { field: "company_name", headerName: "Company Name", width: 380 },
      { field: "country", headerName: "Country", width: 150 },
      { field: "isd_no", headerName: "Country Code", width: 130 },
      { field: "phone_no", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 250 },

      { field: "message", headerName: "Message", width: 130, renderCell: (params) => <MessageCell value={params.value} />, },
    ],

    //  table dummy datas
    TableData: [],
  };
};
