import makeStyles from "@mui/styles/makeStyles";

export const BlogUseStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
  },
  inputBox: {
    "& .MuiTextField-root": {},
  },
  frstRow: {
    // Adjust the width of the first row as needed
    width: "99%", // Set to 100% to occupy the full width of the modal
    height: "60px",
    borderRadius: "8px",
    padding: "12px",
    margin: "6px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",

    "& .MuiTextField-root": {
      "& label": {
        lineHeight: ".9rem",
        color: "#ababab",
      },
      "& div": {
        "& fieldset": {
          border: "none",
        },
      },
      "& .MuiInputBase-input": {
        padding: "0px",
        paddingLeft: "12px",
      },
    },
  },
  finalRow: {
    // Adjust the width of the final row as needed
    width: "100%", // Set to 100% to occupy the full width of the modal
    borderRadius: "8px",
    padding: "12px",
    margin: "6px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    position: "relative",
  },
  addIconBox: {
    backgroundColor: "#464775",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    height: "40px",
    boxShadow: "0 5px 20px #0000000A",
  },
  btnGrp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    backgroundColor: "#f45e2d",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "17px",
    color: "#fff",
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "6px 14px",
  },
  unactive: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    margin: 5,
  },
  active: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
    margin: 5,
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "16px",
  },
  eventName: {
    color: "black",
    fontSize: "16px",
    marginBottom: "12px",
    fontWeight: 600,
  },
  eventTitle: {
    color: "black",
    fontSize: "24px",
    marginBottom: "12px",
    fontWeight: 600,
    textAlign: "center",
  },
  eventImg: {
    borderRadius: "6px",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    paddingLeft: "12px",
  },
  button: {
    backgroundColor: "#f45e2d",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: "none",
    display: "inline-block",
  },
}));
