import React from "react";
import { withNavBars } from "../../HOCs";
import { AboutUsIconUpload } from "./aboutUsUpload";

class AboutUsIconUploadParent extends React.Component {
  render() {
    return <AboutUsIconUpload />;
  }
}

export default withNavBars(AboutUsIconUploadParent);
