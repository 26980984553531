import makeStyles from "@mui/styles/makeStyles";

export const EveryOneBecomePartnerStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    "& span": {
      display: "flex",
      justifyContent: "end",
      // marginBottom: "12px",
    },
  },
  btn: {
    backgroundColor: "#f45e2d",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "17px",
    color: "#fff",
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "6px 14px",
  },
}));
