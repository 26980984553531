import { LocalizationProvider, MobileDateRangePicker } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";
import { useTranslation } from "react-i18next";
import { SelectField, TableComponent, TopNavBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { PartnerShipStyles } from "./style";
import { PartnerShipJson } from "./tableData";

import { subDays } from "date-fns";
import { countryCode } from "../demo/utils";
import { startOfMonth, endOfMonth, subMonths, lightFormat } from "date-fns";

export const PartnerShipEnquiry = () => {
  const { t } = useTranslation(["topBar"]);

  const [PartnershipData, setPartnershipData] = useState([]);
  const [switchActive, setSwitchActive] = React.useState(true);
  const [searchVal, setSearchVal] = React.useState("");
  const [filterData, setFilterData] = React.useState({
    country: "",
    dayType: "",
  });

  const classes = PartnerShipStyles();

  const handleRowclick = () => {};

  React.useEffect(() => {
    getPartnerShip(searchVal, switchActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchActive]);

  const [pageSize, setPageSize] = React.useState(10);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [value, setValue] = React.useState([null, null]);

  const getPartnerShip = (txtSearch, filter) => {
    let params = {
      search: txtSearch ? txtSearch : "",
      limit: pageSize,
      offeset: pageOffset,
    };
    if (filter) {
      params["is_active"] = [filter];
    }
    if (value.filter((i) => Boolean(i)).length === 2) {
      let tempValue = value;
      // .map((i) => i.format("YYYY-MM-DD"));

      params["start_date"] = tempValue[0];
      params["end_date"] = tempValue[1];
    }
    NetworkCall(
      `${config.api_feature}api/v1/people_info/get_all_enquiries`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res.data;
        setPartnershipData(
          response?.data?.map((item, index) => {
            return { sl: index + 1, ...item };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDateFilter = (txtSearch, filter) => {
    setFilterData({
      ...filterData,
      [txtSearch]: filter,
    });

    let params = {
      // search: txtSearch ? txtSearch :"",
      // txtSearch ? txtSearch :
      limit: pageSize,
      offeset: pageOffset,
    };

    if (!!filter?.value?.start_date) {
      params["start_date"] =
        filter?.value?.start_date ?? filterData?.dayType?.value?.start_date;
      params["end_date"] =
        filter?.value?.end_date ?? filterData?.dayType?.value?.end_date;
      params["country"] =
        filter?.countryName ?? filterData?.country?.countryName;
    }

    NetworkCall(
      `${config.api_feature}api/v1/people_info/get_all_enquiries`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res.data;
        let result = response?.data?.map((item, index) => {
          return { sl: index + 1, ...item };
        });
        setPartnershipData([...result]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = [
    {
      label: "Today",
      value: {
        start_date: lightFormat(new Date(), "yyyy-MM-dd"),
        end_date: lightFormat(new Date(), "yyyy-MM-dd"),
      },
    },
    {
      label: "Yesterday",
      value: {
        start_date: lightFormat(subDays(new Date(), 1), "yyyy-MM-dd"),
        end_date: lightFormat(subDays(new Date(), 1), "yyyy-MM-dd"),
      },
    },
    {
      label: "This Month",
      value: {
        start_date: lightFormat(startOfMonth(new Date()), "yyyy-MM-dd"),
        end_date: lightFormat(endOfMonth(new Date()), "yyyy-MM-dd"),
      },
    },
    {
      label: "Last 3 Months",
      value: {
        start_date: lightFormat(subMonths(new Date(), 3), "yyyy-MM-dd"),
        end_date: lightFormat(new Date(), "yyyy-MM-dd"),
      },
    },
    {
      label: "All",
      value: {
        start_date: null,
        end_date: null,
      },
    },
  ];

  const excelJson = [
    {
      columns: Object.keys(PartnershipData?.[0] ?? {})?.map((i) => {
        return { title: i, width: { wpx: 80 } };
      }),
      data: PartnershipData.map((val) => {
        return {
          data: Object.keys(val).map((i) => {
            return {
              value: val[i] ?? "",
            };
          }),
        };
      }).map((i) => i.data),
    },
  ];

  return (
    <>
      <TopNavBar title={t("Partnership")} />
      <div className={classes.root}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "start", end: "end" }}
            >
              <MobileDateRangePicker
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  setPageOffset(0);
                  getPartnerShip();
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField size="small" {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField size="small" {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            <ExcelFile
              style={{ display: "inline-flex" }}
              element={
                <Typography
                  className={classes.btn}
                  style={{ margin: 20 }}
                  variant="h4"
                >
                  Download CSV
                </Typography>
              }
            >
              <ExcelSheet dataSet={excelJson} name="asdasd" />
            </ExcelFile>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexGrow: "1",
            }}
          >
            <Box style={{ width: "100%" }}>
              <SelectField
                fullWidth
                // width={"200px"}
                // label={"label"}
                placeholder={"Filter by Date"}
                value={filterData?.dayType}
                onChange={(value) => getDateFilter("dayType", value)}
                // error={data.error.companyName.length > 0}
                // errorMsg={data.error.companyName}
                options={options}
                // required
              />
            </Box>
            <Box style={{ width: "100%", marginLeft: "10px" }}>
              <SelectField
                fullWidth
                // label={"Country"}
                placeholder={"Filter by Country"}
                value={filterData?.country}
                onChange={(value) => getDateFilter("country", value)}
                // error={data.error.companyName.length > 0}
                // errorMsg={data.error.companyName}
                options={countryCode}
                // required
              />
            </Box>
          </div>
        </div>
        <TableComponent
          dataList={PartnershipData}
          Header={PartnerShipJson()}
          cellClick={(rowData) => handleRowclick(rowData)}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => {
            setPageOffset(newPage);
            getPartnerShip();
          }}
        />
      </div>
    </>
  );
};
