import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  Typography,
  InputAdornment,
  TextField,
  Button,
  Menu,
  MenuItem,
  Hidden,
} from "@mui/material";
// import { LocalStorageKeys } from "../../../utils";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Drawer } from "@mui/material";
import { SideNavBar } from "..";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Switch from "@mui/material/Switch";

// material UI styles
const useStyles = makeStyles((theme, props) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    display: "block",
    marginLeft: "6px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
    },
    "& .MuiTypography-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  search: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f45e2d !important",
      color: theme.palette.primary.contrastText,
      paddingRight: "0px",
    },
  },
  searchmd: {
    margin: "0px 10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f45e2d !important",
      color: theme.palette.primary.contrastText,
      paddingRight: "0px",
    },
  },
  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
  },

  dropdownbutton: {
    padding: 10,
    cursor: "pointer",
    marginLeft: "3px",
    display: "flex",
    opacity: (props) => (props.action ? 1 : 0.7),
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      justifyContent: "center",
    },
  },
  filter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 30px",
    cursor: "pointer",
    "& .MuiTypography-root": {
      marginLeft: "8px",
    },
  },
}));
// end

export const TopNavBar = (props) => {
  // use classes
  const classes = useStyles(props);
  const history = useHistory();
  // end

  // to maintain state
  const [state, setState] = React.useState({
    openSideNavBar: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  // end

  // finctions start
  // side nav bar
  const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar,
    });
  };
  //   dropdown menu
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // end
  //  top dropdown menu
  const [topNav, settopNav] = React.useState(null);
  const navbaropen = Boolean(topNav);
  const navhandleClick = (event) => {
    settopNav(event.currentTarget);
  };
  const navhandleClose = () => {
    settopNav(null);
  };
  // end
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuIcon}
            onClick={toogleSideNavBar}
            size="large"
          >
            <MenuIcon htmlColor="white" />
          </IconButton>

          {/* topnavbar tittle */}
          <div className={classes.titleContainer}>
            {props?.back && (
              <ArrowCircleLeftOutlinedIcon
                onClick={() =>
                  history.push({
                    pathname: props?.back,
                  })
                }
              />
            )}

            <Typography className={classes.title} variant="h6" noWrap>
              {props?.title || "Home"}
            </Typography>
          </div>

          <div className={classes.grow} />

          <Drawer
            open={state.openSideNavBar}
            variant={"temporary"}
            anchor="left"
            onClose={toogleSideNavBar}
          >
            <div style={{ width: 320 }}>
              <SideNavBar isMobile={true} />
            </div>
          </Drawer>

          {/* mobile topnavbar */}
          <Hidden smUp>
            {/* action option */}
            <div></div>
            {/* seacrh text field */}
            {props.isSearch && (
              <TextField
                size="small"
                type="search"
                placeholder="search..."
                className={classes.searchmd}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <div>
              {/* menus */}
              <Button
                id="basic-button1"
                aria-controls={navbaropen ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={navbaropen ? "true" : undefined}
                onClick={navhandleClick}
                style={{ color: "#fff" }}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu1"
                anchorEl={topNav}
                open={navbaropen}
                onClose={navhandleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                className={classes.menuBack}
              >
                {/* filter option */}
                {props?.isFilter && (
                  <MenuItem>
                    <div className={classes.filter}>
                      <FilterAltOffIcon />
                      <Typography>Filter</Typography>
                    </div>
                  </MenuItem>
                )}
                {/* action option */}
                {props.isAction && (
                  <div>
                    <div
                      onClick={handleClick}
                      className={classes.dropdownbutton}
                    >
                      Actions{" "}
                      <KeyboardArrowDownIcon style={{ marginBottom: -6 }} />
                    </div>

                    {props?.action && (
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>Delete</MenuItem>
                        <MenuItem onClick={handleClose}>
                          Mark as inactive
                        </MenuItem>
                      </Menu>
                    )}
                  </div>
                )}
                {/* button option */}
                {props.buttonLabel && (
                  <MenuItem onClick={navhandleClose}>
                    <Button
                      variant="contained"
                      className={classes.createButton}
                      onClick={props?.functions}
                      disabled={props?.disabled}
                    >
                      {props.buttonLabel}
                    </Button>
                  </MenuItem>
                )}
              </Menu>
            </div>
          </Hidden>

          {/* destop && tablet tonavbar */}
          <Hidden smDown>
            {/* search textfield */}
            {props.isSearch && (
              <TextField
                size="small"
                type="search"
                placeholder="search here..."
                className={classes.search}
                onChange={(e) => props?.onChangeSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {/* filter */}
            {props?.isFilter && (
              <div className={classes.filter}>
                {/* {/* <FilterAltOffIcon /> */}
                <Typography style={{ marginRight: "8px" }}>
                  {props?.switchActive ? "Active" : "inActive"}
                </Typography>
                <div>
                  <Switch
                    checked={props?.switchActive}
                    onChange={props?.handleFilterSwitchChange}
                    name="activefilterSwitch"
                    color="secondary"
                  />
                </div>
              </div>
            )}
            {/* button */}
            {props.buttonLabel && (
              <Button
                variant="contained"
                className={classes.createButton}
                onClick={props?.functions}
                disabled={props?.disabled}
              >
                {props.buttonLabel}
              </Button>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};
