import Axios from "axios";
import { Routes } from "../router/routes";
// import { AlertContext } from "../contexts";
// import { AlertProps } from "../utils";
import React from "react";

export const NetworkCall = (
  url,
  method,
  body,
  headers,
  isAuthorized = false,
  notValidateURL = false
) => {
  // const alert = React.useContext(AlertContext);
  //Initializing the header
  let newHeader = headers;

  //Adding Authorization to headers if it is requested
  if (isAuthorized) {
    newHeader = {
      ...headers,
      Authorization: "Bearer " + localStorage.getItem("auth_token"),
    };
  }

  return Axios({
    method: method,
    url: url,
    data: body,
    headers: newHeader,
  }).catch((error) => {
    if (error?.response?.status === 403) {
      window.location.href = Routes.login;
      // alert.setSnack({
      //   ...alert,
      //   open: true,
      //   severity: AlertProps.severity.success,
      //   msg: "Your Session has been ended, Login Again!",
      // });
    }
  });
};
