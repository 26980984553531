import React from "react";
import { withNavBars } from "../../HOCs";
import { PricingEnquiry } from "./pricingEnquiry";

class PricingParent extends React.Component {
  render() {
    return <PricingEnquiry />;
  }
}

export default withNavBars(PricingParent);
