import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog, TableComponent, TopNavBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { CreatePopup } from "./createPopup";
import { EventUseStyles } from "./style";
import { EventReqJson } from "./tableData";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import ViewEvent from "./viewEvent";
import { imageUploads } from "../blogs/utils";

export const Event = (props) => {
  const { t } = useTranslation(["topBar"]);
  const alert = React.useContext(AlertContext);
  const classes = EventUseStyles();

  const initialState = {
    from: "",
    to: "",
    eventType: [],
    eventName: "",
    description: "",
    event_title: "",
    event_master_id: "",
    event_url: "",
    id: "",
    file_image: null,
    // event_title: "",
    file_image_url: "",
    created_at: null,
    is_active: false,
    error: {
      eventType: [],
      eventName: "",
      description: "",
      event_title: "",
      event_url: "",
      from: "",
      to: "",
      event_master_id: "",
      file_image: "",
      file_image_url: "",
      created_at: null,
      is_active: false,
    },
  };

  const [value, setValue] = React.useState({ ...initialState });
  const [eventList, setEventList] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const [popup, setpopup] = React.useState(false);
  const [event, setEvent] = React.useState({
    open: false,
    is_active: false,
  });
  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });

  const viewEvent = (params) => {
    setEvent({ open: true, ...params });
  };

  const fileOnChange = async (e) => {
    const samplevalue = JSON.parse(JSON.stringify(value));
    const updateTheImage = await imageUploads(e?.target?.files[0]);
    if (!e) {
      samplevalue.file_image_url = "";
      samplevalue.file_image = null;
      // setValue({ ...value, file_image_url: "" });
      setValue({ ...samplevalue });
      return;
    }
    //
    samplevalue.file_image = e.target.files[0];
    setValue({
      ...value,
      file_image: samplevalue,
      file_image_url: updateTheImage?.response,
    });
    //  onChange(e);
  };

  const updateState = (k, v) => {
    setValue({ ...value, [k]: v });
  };

  const Dialog = React.useContext(DialogContext);

  const dialogClose = () => {
    Dialog.setDialog({
      open: false,
    });
  };

  const onImageDelete = () => {
    value.file_image = null;
    setValue({ ...value });
  };

  const handleEventActiveConfirmation = (status) => {
    if (status === true) {
      Dialog.setDialog({
        open: true,
        title: "Are you sure?",
        body: "This will make all events in active, Proceed with caution!",
        positiveBtn: "Agree",
        negativeBtn: "Cancel",
        onOk: () => {
          updateState("is_active", status);
          dialogClose();
        },
        onCancel: () => {
          updateState("is_active", false);
          dialogClose();
        },
      });
    } else {
      updateState("is_active", false);
      dialogClose();
    }
  };

  // const onDrawerEdit = (param, e) => {

  // };
  const isValid = () => {
    let isValid = true;
    let error = value.error;

    if (value?.eventName?.length === 0) {
      isValid = false;
      error.eventName = "Enter Name";
    } else {
      error.eventName = "";
    }

    if (value?.description?.length === 0) {
      isValid = false;
      error.description = "Enter Description";
    } else {
      error.description = "";
    }
    // if (value?.event_master_id?.length === 0) {
    //   isValid = false;
    //   error.event_master_id = "Enter Event Type";
    // } else {
    //   error.event_master_id = "";

    // }
    if (value?.eventType?.value === 2) {
      if (value?.event_url?.length === 0) {
        isValid = false;
        error.event_url = "Enter Event URL";
      } else {
        error.event_url = "";
      }
    } else {
      if (!value?.file_image) {
        isValid = false;
        error.file_image = "Upload Image";
      } else {
        error.file_image = "";
      }
    }
    if (value?.event_title?.length === 0) {
      isValid = false;
      error.event_title = "Enter Event Title";
    } else {
      error.event_title = "";
    }
    // if (!value?.id) {
    //   if (!value?.file_image?.name) {
    //     isValid = false;
    //     error.file_image = "Upload Image";
    //   } else {
    //     error.file_image = "";
    //   }
    // }

    setValue({ ...value, error });
    return isValid;
  };
  const addEvent = () => {
    const formData = new FormData();
    if (isValid()) {
      if (value?.id) {
        if (value.id) {
          formData.append("id", value.id);
        }
        formData.append("name", value.eventName);
        formData.append("description", value.description);
        formData.append("event_master_id", value?.eventType?.value ?? 1);
        formData.append("event_title", value.event_title);
        formData.append("file_image", value?.file_image?.file_image ?? "");
        formData.append("event_url", value.event_url);
        formData.append("is_active", value?.is_active);
      } else {
        formData.append("name", value.eventName);
        formData.append("description", value.description);
        formData.append("event_master_id", value?.eventType?.value ?? 1);
        formData.append("event_title", value.event_title);
        formData.append("file_image", value?.file_image?.file_image ?? "");
        formData.append("event_url", value.event_url);
        formData.append("is_active", value?.is_active);
      }
      NetworkCall(
        `${config.api_feature}api/v1/events/upsert`,
        NetWorkCallMethods.post,
        formData,
        null,
        true,
        false
      )
        .then((res) => {
          let main = res.data;
          getEventList(main);
          // getEventList();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          setValue({ ...initialState });
          setpopup(!popup);
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.EventErr,
          });
        });
    }
    setValue({ ...initialState });
  };

  React.useEffect(() => {
    getEventType();
    getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventType = () => {
    let params = {
      search: "",
    };
    NetworkCall(
      `${config.api_feature}api/v1/events/get_events_master`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.query ?? [];
        let tempOptions = response?.map((item, index) => {
          return {
            label: item.name,
            value: item.id,
            // data:item
          };
        });
        setoptions(tempOptions);
        setValue({ ...value, eventType: tempOptions[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PopupSubmitHandler = () => {
    // if (isValid) {
    //   addEvent();
    // setValue({ ...initialState });
    // setpopup(!popup);

    // } else {
    //   alert("err")
    // }
    addEvent();

    // setValue({ ...initialState });
    // setpopup(!popup);
  };

  const getEventList = () => {
    let params = {
      search: "",
      limit: 100,
      offset: 0,
      is_active: [true, false],
    };
    NetworkCall(
      `${config.api_feature}api/v1/events/get_all_events`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let tempList = res?.data?.data ?? [];
        setEventList(
          tempList?.map((item, index) => {
            return { sl: index + 1, ...item };
          })
        );
        tempList?.map((e, index) => {
          return setValue({
            ...value,
            id: e.id,
            is_active: e.is_active,
            sl: index,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDrawerEdit = (row, param, event, rowIndex) => {
    setValue({
      ...value,
      id: row?.id ?? "",
      eventName: row?.name ?? "",
      eventType: {
        label: row?.event_name,
        value: row?.event_master_id,
      },
      event_master_id: row?.event_master_id ?? "",
      description: row?.description ?? "",
      event_title: row?.event_title ?? "",
      file_image: row?.image_url ?? "",
      file_image_url: row?.image_url ?? "",
      is_active: row?.is_active ?? "",
      event_url: row?.event_url ?? "",
    });
    setEdit({ ...edit, isEdit: true, editIndex: rowIndex });
    setpopup(true);
    // editEvent()
  };

  // const onDelete = (row) => {
  //   const eventId = row?.id;
  //   //
  // };
  const onDelete = (row) => {
    let params = {
      id: row?.id,
      update_fields: {
        // "is_active": true ,// on status change
        is_delete: true, // on delete
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/events/update_event`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        // let response = res?.data?.query ?? [];
        // let tempOptions = response?.map((item, index) => {
        //   return {
        //     label: item.name,
        //     value: item.id,

        //     // data:item
        //   };
        // });
        // setoptions(tempOptions);
        // setValue({ ...value, eventType: tempOptions[0] });
        // const eventId = row?.id;
        getEventList();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted Successfully",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const toggle = (row) => {
    //
    let params = {
      id: row?.id,
      update_fields: {
        is_active: !row?.is_active, // on status change
        // "is_delete":true // on delete
      },
    };
    NetworkCall(
      `${config.api_feature}api/v1/events/update_event`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        // let response = res?.data?.query ?? [];
        // let tempOptions = response?.map((item, index) => {
        //   return {
        //     label: item.name,
        //     value: item.id,

        //     // data:item
        //   };
        // });
        // setoptions(tempOptions);
        // setValue({ ...value, eventType: tempOptions[0] });
        // const eventId = row?.id;
        //
        getEventList();
        if (!row?.is_active === true) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Event is Activated",
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Event is Deactivated",
          });
        }
        // alert.setSnack({
        //   ...alert,
        //   open: true,
        //   severity: AlertProps.severity.success,
        //   msg :  !row?.is_active ? "Event is Activated" : "Event is Deactivated"
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const popupClose = () => {
    setpopup(!popup);
    setValue({ ...initialState });
  };

  return (
    <>
      <TopNavBar title={t("Event")} buttonLabel={false} />
      <div className={classes.root}>
        {/* <ExcelFile style={{ display: "flex" }} element={<Typography className={classes.btn}>Download CSV</Typography>}>
                    <ExcelSheet dataSet={excelJson} name="fdskj" />
                </ExcelFile> */}
        <Box className={classes.btnBox}>
          <Stack direction="row" className={classes.btnGrp}>
            <Typography
              className={classes.btn}
              onClick={() => {
                setpopup(!popup);
              }}
            >
              Create Event
            </Typography>
            {/* <Typography className={classes.btn} ml={2}>
              Filter
            </Typography> */}
          </Stack>
        </Box>

        <TableComponent
          dataList={eventList}
          Header={EventReqJson(onDrawerEdit, viewEvent, toggle, onDelete)}
          isChecked
          cellClick={(rowData) => console.log(rowData)}
        />

        <AlertDialog
          open={popup}
          onClose={() => popupClose()}
          header={value?.id ? "Edit Event" : "Create Event"}
          // "Add Event"
          component={
            <CreatePopup
              options={options}
              value={value}
              updateState={updateState}
              PopupSubmitHandler={PopupSubmitHandler}
              fileOnChange={fileOnChange}
              onImageDelete={onImageDelete}
              handleEventActiveConfirmation={handleEventActiveConfirmation}
              edit={value?.id ? true : false}
            />
          }
          meidum
        />
        <ViewEvent
          {...event}
          handleClose={() => {
            setEvent({ ...event, open: false });
          }}
        />
      </div>
    </>
  );
};
