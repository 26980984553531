import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  DialogTitle,
  Typography,
} from "@mui/material";
import { EventUseStyles } from "./style";

const ViewEvent = (props) => {
  const classes = EventUseStyles();
  let { open, handleClose, event_title, url, name, description } = props;

  const myArray = url?.split(".");

  const indexOfFormat = myArray?.length - 1;
  const format = myArray?.[indexOfFormat];
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.eventTitle}>{event_title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {format === "mp4" ? (
              <video
                height="100%"
                width="100%"
                className={classes.eventvideo}
                autoPlay="true"
                // loop
              >
                <source src={url} type="video/mp4" />
              </video>
            ) : (
              <img
                className={classes.eventImg}
                src={url}
                alt={name}
                height="100%"
                width="100%"
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.eventName}>{name}</Typography>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEvent;
