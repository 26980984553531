import React from "react";
import { withNavBars } from "../../HOCs";
// import { Event } from "./event";
import { LandingPageUpload } from "./landingPageUploads";

class LandingPageUploadParent extends React.Component {
  render() {
    return <LandingPageUpload />;
  }
}

export default withNavBars(LandingPageUploadParent);
