import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const EventReqJson = (onEdit, onView, onDelete) => {
  return {
    TablecolumnData: [
      // { field: "name", headerName: "Name", width: 140 },
      { field: "sl", headerName: "S.No", width: 100 },
      { field: "file_img", headerName: "Image Name", width: 500 },
      { field: "priority", headerName: "Position", width: 150 },
      {
        field: "view",
        headerName: "View",
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => onView(params?.row)}>
              <VisibilityIcon />
            </IconButton>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onEdit(params.row, params, e)}>
              <EditIcon />
            </IconButton>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onDelete(params.row, params, e)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    TableData: [],
  };
};
