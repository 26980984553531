import React from "react";
import { withNavBars } from "../../HOCs";
import { PartnerShipEnquiry } from "./PartnerShipEnquiry";

class PartnerShipParent extends React.Component {
  render() {
    return <PartnerShipEnquiry />;
  }
}

export default withNavBars(PartnerShipParent);
