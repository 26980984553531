import React from "react";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "77vh",
    width: "100%",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&.MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "0px",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },

  DataGrid: {
    background: "#fff",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 9%)",
    textAlign: "center",
    width: "100%",
    height: "100%",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      "& div": {
        width: "100%",
        height: "100%",
      },
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  },
}));

export function TableComponent(props) {
  // use classes
  const classes = useStyles(props);

  const {
    dataList,
    Header,
    isrowCount,
    showAction,
    cellClick,
    pageSize,
    // setPageSize,
    setPageSize = () => false,
    onPageChange,
  } = props;
  return (
    <div className={`${classes.DataGrid} ${classes.root}`}>
      <DataGrid
        className={classes.root}
        rows={dataList}
        columns={Header?.TablecolumnData}
        disableColumnMenu
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        sortingMode={"server"}
        hideFooterSelectedRowCount={isrowCount}
        onSelectionModelChange={showAction}
        onRowClick={cellClick}
        ExtendRowFullWidth={true}
        onPageChange={onPageChange}
      />
    </div>
  );
}
