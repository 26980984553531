import moment from "moment";

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};

export const EveryOneBecomePartnerJson = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "sl", headerName: "S.No", width: 80 },
      {
        field: "created_at",
        headerName: "Requested on",
        width: 150,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      { field: "first_name", headerName: "Name", width: 190 },
      { field: "company_name", headerName: "Company Name", width: 350 },
      { field: "country", headerName: "Location", width: 130 },
      { field: "desigination", headerName: "Designation", width: 200 },
      { field: "phone_no", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "become", headerName: "Become", width: 200 },
    ],

    //  table dummy datas
    TableData: [],
  };
};
