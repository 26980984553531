import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: "100% !important",
      borderRadius: "4px",
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: "relative",
    },
    "& .MuiDialog-container": {
      padding: (props) => props?.padding ?? "",
    },
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize: "16px",
    fontFamily: "crayond_regular",
    fontWeight: "800",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export function AlertDialog(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props?.onClose}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        maxWidth={props?.medium ? "lg" : "xs"}
        fullWidth
        aria-describedby="alert-dialog-description"
      >
        {!props?.isnotTitle && (
          <DialogTitle className={classes.header}>
            <span>{props?.header}</span>{" "}
            <img
              src="/images/close.svg"
              alt="close"
              onClick={props?.onClose}
              style={{ cursor: "pointer" }}
            />
          </DialogTitle>
        )}

        <DialogContent style={{ padding: "0 !important" }}>
          {props?.component}
        </DialogContent>
      </Dialog>
    </div>
  );
}
