import React from "react";
import { withNavBars } from "../../HOCs";
import { CareersUpload } from "./careersUpload";

class CareersUploadParent extends React.Component {
  render() {
    return <CareersUpload />;
  }
}

export default withNavBars(CareersUploadParent);
