import React from "react";
import { withNavBars } from "../../HOCs";
import { Event } from "./event";

class EventParent extends React.Component {
  render() {
    return <Event />;
  }
}

export default withNavBars(EventParent);
