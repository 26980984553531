import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CareersUseStyles } from "./style";

export const CreatePopup = (props) => {
  const {
    options,
    countryOptions,
    cityOptions,
    value,
    updateState,
    PopupSubmitHandler,
    handleEventActiveConfirmation,
  } = props;

  const classes = CareersUseStyles();
  return (
    <Grid container>
      {/* position */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box fullWidth className={classes.frstRow}>
          <TextField
            fullWidth
            label="Position"
            value={value.position_name}
            onChange={(e) => {
              updateState("position_name", e.target.value);
            }}
            error={value.error.position_name.length > 0}
            errorMsg={value.error.position_name}
          />
        </Box>
        {value.error.position_name.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.position_name}
          </Typography>
        )}
      </Grid>
      {/* department */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="eventType"
            options={options ?? []}
            getOptionLabel={(option) => option?.label}
            autoSelect
            renderInput={(params) => (
              <TextField {...params} label="Department" />
            )}
            // defaultValue={{ label: "Sales", value: 1 }}
            value={value?.department_name}
            onChange={(event, newValue) => {
              updateState("department_name", newValue);
            }}
          />
        </Box>
      </Grid>
      {/* country */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="country_name"
            options={countryOptions ?? []}
            // countryOptions={countryOptions ?? []}
            getOptionLabel={(countryOptions) => countryOptions?.label}
            autoSelect
            renderInput={(params) => <TextField {...params} label="Country" />}
            // defaultValue={{ label: "webinar", value: 1 }}
            value={value?.country_name}
            onChange={(event, newValue) => {
              updateState("country_name", newValue);
            }}
          />
        </Box>
      </Grid>
      {/* city */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="location_name"
            options={cityOptions ?? []}
            // cityOptions={cityOptions ?? []}
            getOptionLabel={(cityOptions) => cityOptions?.label}
            autoSelect
            renderInput={(params) => <TextField {...params} label="City" />}
            // defaultValue={{ label: "webinar", value: 1 }}
            value={value?.location_name}
            onChange={(event, newValue) => {
              updateState("location_name", newValue);
            }}
          />
        </Box>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <Box className={classes.frstRow}> */}
        <div className={classes.labelText}>Job Description :</div>

        <ReactQuill
          style={{ margin: "10px" }}
          theme="snow"
          label="Job Description"
          value={value?.job_description}
          onChange={(e, a, b, c) => {
            if (b === "user") {
              updateState("job_description", e);
            }
          }}
          error={value.error.job_description.length > 0}
          errorMsg={value.error.job_description}
        />
        {/* </Box> */}
        {value.error.job_description.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.job_description}
          </Typography>
        )}
      </Grid>

      {/* task_responsibilities */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <Box className={classes.frstRow}> */}
        <div className={classes.labelText}>Responsibility :</div>

        <ReactQuill
          style={{ margin: "10px" }}
          theme="snow"
          label="Responsibility"
          value={value?.task_responsibilities}
          onChange={(e, a, b, c) => {
            if (b === "user") {
              updateState("task_responsibilities", e);
            }
          }}
          error={value.error.task_responsibilities.length > 0}
          errorMsg={value.error.task_responsibilities}
        />
        {/* </Box> */}
        {value.error.task_responsibilities.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.task_responsibilities}
          </Typography>
        )}
      </Grid>

      {/* Qualifications */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <Box className={classes.frstRow}> */}
        <div className={classes.labelText}>Qualification :</div>

        <ReactQuill
          style={{ margin: "10px" }}
          theme="snow"
          label="Qualification"
          value={value?.qualification}
          onChange={(e, a, b, c) => {
            if (b === "user") {
              updateState("qualification", e);
            }
          }}
          error={value.error.qualification.length > 0}
          errorMsg={value.error.qualification}
        />
        {/* </Box> */}
        {value.error.qualification.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.qualification}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Mail -ID"
            value={value.email_id}
            onChange={(e) => {
              updateState("email_id", e.target.value);
            }}
            error={value.error.email_id.length > 0}
            errorMsg={value.error.email_id}
          />
        </Box>
        {value.error.email_id.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.email_id}
          </Typography>
        )}
      </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className={classes.frstRow}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  toolbarFormat="YYYY-MM-DD hh:mm:ss"
                  label="Start date"
                  value={value.from}
                  onChange={(e) => updateState("from", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className={classes.frstRow}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  toolbarFormat="YYYY-MM-DD hh:mm:ss"
                  label="End date"
                  value={value.to}
                  onChange={(e) => updateState("to", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.finalRow}>
          {value?.file_image_url || value?.file_image ? (
            <img
              style={{ height: 100, width: 380 }}
              src={
                value?.file_image
                  ? value?.file_image
                  : "data:image;base64," + value?.file_image_url
              }
            />
          ) : (
            <Upload fileOnChange={fileOnChange} single />
          )}
          {(value?.file_image_url || value?.file_image) && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => fileOnChange()}
            >
              {" "}
              <DeleteForeverIcon style={{ color: "#FF300F" }} />
            </div>
          )}
        </Box>
      </Grid> */}
      {/* {value.error.file_image?.name?.length > 0 ? "true" : "false"} */}
      {/* {value.error.file_image && (
        <Typography className={classes.errorMsg}>
          {value.error.file_image}
        </Typography>
      )} */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.is_active}
                onChange={(event) => {
                  handleEventActiveConfirmation(event?.target?.checked);
                }}
              />
            }
            label="Mark as active"
          />
        </FormGroup>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ padding: "8px 16px 14px 16px" }}
      >
        <Typography
          className={classes.btn}
          onClick={(e) => PopupSubmitHandler(e)}
        >
          Create
        </Typography>
      </Grid>
    </Grid>
  );
};
