import React from "react";
import { withNavBars } from "../../HOCs";
import { EventMembers } from "./event_users";

class EventMemberParent extends React.Component {
  render() {
    return <EventMembers />;
  }
}
export default withNavBars(EventMemberParent);
