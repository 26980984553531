export const DemoReqJson = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "sl", headerName: "S.No", width: 80 },
      { field: "first_name", headerName: "First Name", width: 150 },
      { field: "last_name", headerName: "Last Name", width: 130 },
      { field: "company", headerName: "Company Name", width: 400 },
      { field: "country", headerName: "Country", width: 150 },
      { field: "mobile_no", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 150 },
      { field: "message", headerName: "Message", width: 500 },
      // { field: "event_name", headerName: "Event Name", width: 150 },
      // {
      //   field: "created_at",
      //   headerName: "Requested on",
      //   width: 150,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
    ],

    //  table dummy datas
    TableData: [],
  };
};
