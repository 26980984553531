import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Switch } from "../../components/switcher";
import DeleteIcon from "@mui/icons-material/Delete";

export const EventReqJson = (onEdit, onView, onSwitchBtn, onDelete) => {
  return {
    TablecolumnData: [
      // { field: "name", headerName: "Name", width: 140 },
      { field: "sl", headerName: "S.No", width: 80 },
      { field: "event_title", headerName: "Event Title", width: 500 },
      // {
      //   field: "from",
      //   headerName: "From",
      //   width: 140,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
      // {
      //   field: "to",
      //   headerName: "To",
      //   width: 140,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
      // { field: "event_name", headerName: "Event Type", width: 150 },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton disableRipple onClick={() => onSwitchBtn(params?.row)}>
              <Switch checked={params?.row?.is_active} />
            </IconButton>
          );
        },
      },
      {
        field: "view",
        headerName: "View",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => onView(params?.row)}>
              <VisibilityIcon />
            </IconButton>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onEdit(params.row, params, e)}>
              <EditIcon />
            </IconButton>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onDelete(params.row, params, e)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    TableData: [],
  };
};
