import React from "react";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  input: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  uploadContainer: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  uploadIcon: {
    fontSize: "58px",
    color: "#5078E1",
  },
  uploadText: {
    color: "#5078E1",
    fontSize: "12px",
    padding: 15,
    fontFamily: "tenant_semiBold",
    
  },
}));

const Upload = ({ fileOnChange, isLoading }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <div className={classes.uploadContainer}>
          <div className={classes.root}>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/svg, image/webp, image/avif, image/apng, video/mp4"
              onChange={(e) => fileOnChange(e)}
              className={classes.input}
              disabled={isLoading}
            />
            
            <IconButton onClick={() => document.querySelector('input[type="file"]').click()}>
              <DriveFolderUploadOutlined className={classes.uploadIcon} />
            </IconButton>
            <Typography className={classes.uploadText}>Upload</Typography>
            {isLoading && (
              <CircularProgress
                sx={{ color: "#5078E1", height: "24px", width: "24px" }}
              />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Upload;
