import React from "react";
import { withNavBars } from "../../HOCs";
import { Blogs } from "./blogs";

class BlogsParent extends React.Component {
  render() {
    return <Blogs />;
  }
}

export default withNavBars(BlogsParent);
