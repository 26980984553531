import React from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box } from "@mui/system";
import { Upload } from "../../components/upload/fileupload";
import { EventUseStyles } from "./style";

export const CreatePopup = (props) => {
  const {
    options,
    value,
    updateState,
    PopupSubmitHandler,
    fileOnChange,
    onImageDelete,
    handleEventActiveConfirmation,
    edit,
  } = props;
  const classes = EventUseStyles();
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <Autocomplete
            id="eventType"
            options={options ?? []}
            getOptionLabel={(option) => option?.label}
            autoSelect
            renderInput={(params) => (
              <TextField {...params} label="Event Type" />
            )}
            // defaultValue={{ label: "webinar", value: 1 }}
            value={value?.eventType}
            onChange={(event, newValue) => {
              updateState("eventType", newValue);
            }}
          />
        </Box>
        {/* {value.error.event_master_id.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.event_master_id}
          </Typography>
        )} */}
      </Grid>
      {/* {value.error.eventName } */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Event Name"
            value={value.eventName}
            onChange={(e) => {
              updateState("eventName", e.target.value);
            }}
            error={value.error.eventName.length > 0}
            // errorMsg={value.error.eventName}
          />
        </Box>
        {value.error.eventName.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.eventName}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Event title"
            value={value.event_title}
            onChange={(e) => {
              updateState("event_title", e.target.value);
            }}
            error={value.error.event_title.length > 0}
            errorMsg={value.error.event_title}
          />
        </Box>
        {value.error.event_title.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.event_title}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.frstRow}>
          <TextField
            fullWidth
            label="Description"
            value={value.description}
            onChange={(e) => {
              updateState("description", e.target.value);
            }}
          />
        </Box>
        {value.error.description.length > 0 && (
          <Typography className={classes.errorMsg}>
            {value.error.description}
          </Typography>
        )}
      </Grid>

      {value?.eventType?.label === "Event" && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.frstRow}>
            <TextField
              fullWidth
              label="Event url"
              value={value.event_url}
              onChange={(e) => {
                updateState("event_url", e.target.value);
              }}
            />
          </Box>
          {value.error.event_url.length > 0 && (
            <Typography className={classes.errorMsg}>
              {value.error.event_url}
            </Typography>
          )}
        </Grid>
      )}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.finalRow}>
          {value?.file_image_url || value?.file_image ? (
            <img
              style={{ height: 100, width: 380 }}
              src={
                value?.file_image_url
                  ? value?.file_image_url
                  : "data:image;base64," + value?.file_image_url
              }
              alt="upload_image"
            />
          ) : (
            <Upload fileOnChange={fileOnChange} single value={value} />
          )}
          {(value?.file_image_url || value?.file_image) && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => fileOnChange()}
            >
              {" "}
              <DeleteForeverIcon
                onClick={() => onImageDelete()}
                style={{ color: "#FF300F" }}
              />
            </div>
          )}
        </Box>
      </Grid>
      {/* {value.error.file_image?.name?.length > 0 ? "true" : "false"} */}
      {value.error.file_image && (
        <Typography className={classes.errorMsg}>
          {value.error.file_image}
        </Typography>
      )}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.is_active}
                onChange={(event) => {
                  handleEventActiveConfirmation(event?.target?.checked);
                }}
              />
            }
            label="Mark as active"
          />
        </FormGroup>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ padding: "8px 16px 14px 16px" }}
      >
        <Typography
          className={classes.btn}
          onClick={(e) => PopupSubmitHandler(e)}
        >
          {edit ? "Edit" : "Create"}
        </Typography>
      </Grid>
    </Grid>
  );
};
