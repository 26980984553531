import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 8px 69px #0000001A",
    maxWidth: "520px",
    height: "100%",
    padding: "8px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  item: {
    flexDirection: "column",
    display: "flex",
    paddingBottom: "20px",
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  forgetPassword: {
    float: "right",
    fontSize: "12px",
    marginTop: "10px",
  },
  intBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  intBackground: {
    width: "74px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    padding: "12px",
  },
  formContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    "@media(max-width: 900px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  signInTitle: {
    fontSize: "28px",
    color: "#091B29",
    letterSpacing: "0.17px",
    fontWeight: "900",
  },
  subTitle: {
    fontSize: "16px",
    color: "#4E5A6B",
    marginTop: "6px",
    fontWeight: "600",
  },
  inputs: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #E4E8EE",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5078E1",
      },
    },
  },
  loginBtn: {
    background: "#5078E1",
    textTransform: "none",
  },
  butnBottom: {
    paddingBottom: "40px",
    marginTop: "150px",
    "@media(max-width: 900px)": {
      paddingBottom: "20px",
      marginTop: "100px",
    },
  },
  propertyLogo: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%",
    "@media(max-width: 900px)": {
      //  display:"none"
    },
  },
  logo: {
    height: 102,
    width: 322,
    "@media(max-width: 900px)": {
      height: 70,
      width: 250,
    },
  },
  form: {
    padding: "15px",
    "@media(max-width: 900px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  eyeIcon: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
  },
}));

export const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const alert = React.useContext(AlertContext);

  const { t } = useTranslation(["login"]);

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
    // eslint-disable-next-line
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    error: {
      email: "",
      password: "",
    },
  });

  const ValidateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = (key, value) => {
    if (key === "email") {
      if (ValidateEmail(value) === true) {
        let error = values.error;
        error[key] = "";
        setValues({ ...values, [key]: value });
      } else if (value === "") {
        let error = values.error;
        error[key] = "";
        setValues({ ...values, [key]: "" });
      } else {
        let error = values.error;
        error[key] = "Invalid Email";
        setValues({ ...values, [key]: "" });
      }
    } else {
      let error = values.error;
      error[key] = "";
      setValues({ ...values, [key]: value });
    }
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = values.error;

    if (values.email === "") {
      isValid = false;
      error.email = "Enter Email";
    }

    if (values.password === "") {
      isValid = false;
      error.password = "Enter Password";
    }
    if (values.email !== "" && values.password !== "") {
      isValid = true;
    }

    setValues({ ...values, error });
    return isValid;
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const onSendLoginBtnClicked = () => {
    if (isIamValideToLogin()) {
      var payload = {
        email_id: values.email,
        password: values.password,
      };
      NetworkCall(
        `${config.api}api/v1/pasm/login`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          localStorage.setItem(LocalStorageKeys.authToken, response.data.token);
          localStorage.setItem("profileId", response.data.profile_id);
          localStorage.setItem("user_name", response.data.username);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Login Successfully !",
          });
          history.push(Routes.dashboard);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please check credentials",
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <Box
        className={classes.root}
        sx={{
          background:
            config.theme === "dev"
              ? "transparent linear-gradient(119deg, #8FDBFB 0%, #4EA3C8 100%, #000000 100%) 0% 0% no-repeat"
              : "transparent linear-gradient(119deg, #F3F3F3 0%, #CCCCCC 100%) 0% 0% no-repeat",
          // "transparent linear-gradient(119deg, #A8E063 0%, #56AB2F 100%) 0% 0% no-repeat",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.propertyLogo}>
              <img
                src="/images/property-logo.png"
                className={classes.logo}
                alt="logo"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.form}>
              <div className={classes.container}>
                <Box className={classes.intBox}>
                  <Box
                    className={classes.intBackground}
                    sx={{
                      backgroundColor:
                        config.theme === "dev" ? "#50A5C9" : "#FAE7E6",
                    }}
                  >
                    {config.theme === "dev" ? (
                      <img src="/images/init.svg" alt="int" />
                    ) : (
                      <img src="/images/live.svg" alt="int" />
                    )}
                    <Typography
                      color={config.theme === "dev" ? "#ffff" : "#F44336"}
                      m={1}
                    >
                      {config.theme === "dev" ? "Int" : "Live"}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.formContainer}>
                  <Box pb={"32px"}>
                    <Typography
                      variant="subtitle1"
                      className={classes.signInTitle}
                    >
                      Sign In
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.subTitle}
                    >
                      Property and facility management solution.
                    </Typography>
                  </Box>

                  <div className={classes.item}>
                    <label
                      style={{
                        color: "#98A0AC",
                        paddingBottom: "8px",
                        fontSize: "13px",
                      }}
                    >
                      {t("Email ID")}
                    </label>
                    <TextField
                      className={classes.inputs}
                      value={values.email_id}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                    {values.error.email !== "" && (
                      <Typography style={{ fontSize: "10px", color: "red" }}>
                        {values.error.email}
                      </Typography>
                    )}
                  </div>

                  <div className={classes.item}>
                    <label
                      style={{
                        color: "#98A0AC",
                        paddingBottom: "8px",
                        fontSize: "13px",
                      }}
                    >
                      {t("Enter Password")}
                    </label>
                    <TextField
                      className={classes.inputs}
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      InputProps={{
                        endAdornment: values.showPassword ? (
                          <Box
                            className={classes.eyeIcon}
                            onClick={handleClickShowPassword}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="/images/Visible.svg"
                              alt="visibilityOn"
                              width={24}
                              height={18}
                            />
                          </Box>
                        ) : (
                          <Box
                            className={classes.eyeIcon}
                            onClick={handleClickShowPassword}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="/images/Hide.svg"
                              alt="visibilityOff"
                              width={24}
                              height={18}
                            />
                          </Box>
                        ),
                      }}
                    />
                    {values.error.password !== "" && (
                      <Typography style={{ fontSize: "10px", color: "red" }}>
                        {values.error.password}
                      </Typography>
                    )}
                  </div>
                  <span className={classes.forgetPassword}>
                    <Typography variant="subtitle2" color={"#091B29"}>
                      {t("Did you forget your password? ")}
                      <span style={{ color: "#5078E1", cursor: "pointer" }}>
                        Click Here
                      </span>
                    </Typography>
                  </span>

                  <Box className={classes.butnBottom}>
                    <Button
                      fullWidth
                      className={classes.loginBtn}
                      variant="contained"
                      size="large"
                      onClick={onSendLoginBtnClicked}
                    >
                      {t("Log In")}
                    </Button>
                  </Box>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
