import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
    height: "100%",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      cursor: "pointer",
      zIndex: 10000,
    },
    "& .MuiButton-root": {
      top: "80px",
    },
    "& .MuiTypography-colorPrimary": {
      marginTop: theme.spacing(11),
    },
  },
  drap: {
    position: "relative",
    height: "159px",
    borderRadius: 10,
    textAlign: "center",
    background: "#DEEAFC",
  },
  drap2: {
    position: "relative",
    height: "159px",
    borderRadius: 10,
    textAlign: "center",
    background: "#DEEAFC",
  },
  text: {
    color: "#5078E1",
    fontSize: "12px",
    padding: 10,
    fontFamily: "tenant_semiBold",
  },
  text2: {
    color: "#5078E1",
    fontSize: "12px",
    padding: 10,
    fontFamily: "tenant_semiBold",
  },
  upload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "30px",
  },
}));

export const Upload = (props) => {
  const { fileOnChange, data, isLoading, value } = props;
  const classes = useStyles(props);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <div className={classes.drap}>
          <div className={classes.root}>
            <div className={classes.upload}>
              {data?.partner_type?.label === "Image" && (
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/svg, image/webp, image/avif, image/apng"
                  onChange={(e) => fileOnChange(e)}
                  disabled={props.disabled}
                />
              )}
              {value?.partner_type?.value === 1 ||
              value?.partner_type?.value === 2 ? (
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/svg, image/webp, image/avif, image/apng"
                  onChange={(e) => fileOnChange(e)}
                  disabled={props.disabled}
                />
              ) : (
                ""
              )}

              {value?.eventType?.value === 1 ||
              value?.eventType?.value === 2 ? (
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/svg, image/webp, image/avif, image/apng"
                  onChange={(e) => fileOnChange(e)}
                  disabled={props.disabled}
                />
              ) : (
                ""
              )}

              {data?.partner_type?.label === "Video" && (
                <input
                  type="file"
                  accept="video/mp4"
                  multiple
                  onChange={(e) => fileOnChange(e)}
                  disabled={props.disabled}
                />
              )}
              <IconButton>
                <DriveFolderUploadOutlined
                  style={{ fontSize: "58px", color: "#5078E1" }}
                />
              </IconButton>
              <Typography className={classes.text}>Upload File</Typography>
              {isLoading ? (
                <CircularProgress
                  sx={{ color: "#5078E1", height: "24px", width: "24px" }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
