import dotenv from "dotenv";
dotenv.config();

let config = {};

config.api_url = `${process.env.REACT_APP_API_PROTOCAL}${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT}${process.env.REACT_APP_API_BASE_URL}`;
config.graphql_url = `${process.env.REACT_APP_GRAPHQL_PROTOCAL}${process.env.REACT_APP_GRAPHQL_HOST}${process.env.REACT_APP_GRAPHQL_PORT}${process.env.REACT_APP_GRAPHQL_BASE_URL}`;
//config.graphql_web_socket_url = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;
config.api = `${process.env.REACT_APP_AUTH_API}`;
config.api_rest = `${process.env.REACT_APP_PLAN_API}`;
config.api_feature = `${process.env.REACT_APP_WEB_API}`;
config.property_automate_api = `${process.env.REACT_APP_PROPERTY_AUTOMATE_API}`;
config.api_key = `${process.env.REACT_APP_X_API_KEY}`;

config.theme = process.env.REACT_APP_THEME;

export { config };
